<template>
	<Layout>
		<Header />
		<div class="swap">
			<div class="columns">
				<div class="column">
					<SelectWithValidation label="From" v-model="sender_id">
						<option v-for="s in users" :selected="s.id == 1" :key="`sender_${s.id}`" :value="s.id">{{ s.full_name }}</option>
					</SelectWithValidation>
				</div>
				<div class="column">
					<SelectWithValidation label="To" v-model="receiver_id">
						<option v-for="s in users" :key="`receiver_${s.id}`" :value="s.id">{{ s.full_name }}</option>
					</SelectWithValidation>
				</div>
			</div>
			<div class="amount">
				<label class="label">You send</label>
        <input class="input mb-4" type="text" v-model.lazy="tokens" v-money="money" :disabled="!sender_id">
				<div v-if="sender_id" class="balance">
					<strong>Balance:</strong> {{ totalTokenSender }}
				</div>
			</div>
			<b-button native-type="submit" class="button is-button is-gradient" :loading="loading" @click="submitTransaction" :disabled="disabledButtonSend">Send</b-button>
		</div>
	</Layout>
</template>

<script>
import SelectWithValidation from '@/components/inputs/SelectWithValidation'
import { successToast, errorToast } from '@/mixins/toast'
import Layout from '@/layouts/Default'
import Header from '@/components/Header'
import { VMoney } from 'v-money'
import Api from '@/services/api'
import { mapActions } from 'vuex'
import '@/mixins/date'

export default {
	components: {
		Layout,
		SelectWithValidation,
		Header
	},
  directives: {
		money: VMoney
	},
	data() {
		return {
			loading: false,
			users: [],
      sender_id: null,
      receiver_id: null,
      tokens: 0,
      money: {
				decimal: '',
				thousands: '.',
				prefix: '',
				precision: 0
			}
		}
	},
	mounted() {
		this.getUsers()
	},
	methods: {
    ...mapActions('user', [
			'getTotalTokens'
		]),
		getUsers() {
			Api.get('users/findSelect')
				.then(({ data }) => {
					this.users = data
				})
		},
    submitTransaction() {
      if (this.sender_id && this.receiver_id && this.tokens) {
        this.loading = true
        Api.post('transactions/send', { sender_id: this.sender_id, receiver_id: this.receiver_id, tokens: this.tokens })
          .then(() => {
            this.sender_id = null
            this.receiver_id = null
            this.tokens = 0

            successToast('Enviado com sucesso')
            this.getTotalTokens()
            this.getUsers()
          })
          .catch(() => {
            errorToast('Erro ao enviar')
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
	},
  computed: {
    totalTokenSender() {
      if (this.sender_id) {
        const user = this.users.find(u => u.id == this.sender_id)

        if (user) {
          return user.tokens_count
        }
      }

      return 0
    },
    disabledButtonSend() {
      return !this.sender_id || !this.receiver_id || this.tokens > this.totalTokenSender || this.tokens == 0 || this.sender_id == this.receiver_id
    }
  }
}
</script>
